@font-face {
  font-family: QuattroNews;
  src: url(./fonts/QuattroNewsRegular.woff2);
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: QuattroNews, Helvetica, "sans-serif";
  width: 90%;
  margin: 0px 5%;
  color: rgb(255, 255, 255);
  background: rgb(25, 24, 23);
}

button {
  font-family: inherit;
}

header {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  h1 {
    margin: 0;
  }
}

section {
  margin-bottom: 1rem;
}

.dev-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;
}

#fullscreen-element {
  width: 100%;
  max-width: 1000px;
}

#auth-iframe {
  position: fixed;
  width: 50%;
  height: 50%;
  margin: auto 25%;
  z-index: 100;
}

#login-status {
  display: flex;
  gap: 5px;
  align-items: center;
}

#logout-button {
  color: #ffffff;
  padding: 0.5em 1em;
  background: #e0001c;
  border-radius: 8px;
  width: 100px;
}

#toggle-login-modal-button {
  color: #ffffff;
  padding: 0.5em 1em;
  background: #e0001c;
  border-radius: 8px;
  width: 100px;
}

#state {
  padding: 1rem;
  border-radius: 0.5rem;
  white-space: pre-wrap;
  background-color: #403f3e;
}

.left-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  max-width: 50px;
  max-height: 50px;
}

.progressbar-handle {
  /* override handlebar opacity so we can see how well the marker behind it aligns */
  opacity: 0.5;
}
