@font-face {
  font-family: QuattroNews;
  src: url("QuattroNewsRegular.0f294942.woff2");
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  color: #fff;
  background: #191817;
  width: 90%;
  margin: 0 5%;
  font-family: QuattroNews, Helvetica, "sans-serif";
}

button {
  font-family: inherit;
}

header {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem 0;
  display: -ms-flexbox;
  display: flex;
}

header h1 {
  margin: 0;
}

section {
  margin-bottom: 1rem;
}

.dev-grid {
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  gap: 1em;
  display: -ms-flexbox;
  display: flex;
}

#fullscreen-element {
  width: 100%;
  max-width: 1000px;
}

#auth-iframe {
  z-index: 100;
  width: 50%;
  height: 50%;
  margin: auto 25%;
  position: fixed;
}

#login-status {
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
  display: -ms-flexbox;
  display: flex;
}

#logout-button, #toggle-login-modal-button {
  color: #fff;
  background: #e0001c;
  border-radius: 8px;
  width: 100px;
  padding: .5em 1em;
}

#state {
  white-space: pre-wrap;
  background-color: #403f3e;
  border-radius: .5rem;
  padding: 1rem;
}

.left-header {
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  display: -ms-flexbox;
  display: flex;
}

.logo {
  max-width: 50px;
  max-height: 50px;
}

.progressbar-handle {
  opacity: .5;
}

/*# sourceMappingURL=index.5c38f0d8.css.map */
